
import { accountRules } from '@/common/js/form-rule/index'
import { URLS } from '@/common/js/constant/index'
import { post } from '@/plugins'
import { reactive, toRefs, defineComponent, onBeforeMount, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { FormInstance, message } from 'ant-design-vue'
import { md5 } from '@/utils/core'
import KlookPhoneInput from '@/components/phone-input.vue'
import { MailOutlined } from '@ant-design/icons-vue'
import Cookie from 'js-cookie'

export default defineComponent({
  components: {
    KlookPhoneInput,
    MailOutlined
  },
  setup() {
    const router = useRouter()
    const { t } = useI18n({})
    const route = useRoute()
    const formRef = ref<FormInstance | any>()
    const state: {
      formRules: any
      isLoading: boolean
      form: any
    } = reactive({
      formRules: {},
      form: {
        token: '',
        token_type: '', // active、forget、appoint
        login_type: '', // email、phone
        username: '',
        new_password: '',
        confirm_password: ''
      },
      isLoading: false
    })

    onBeforeMount(() => {
      Object.assign(state.form, route.query)
      bindRules()
    })
    const bindRules = () => {
      state.formRules = accountRules.call(null, state.form)
    }
    const submit = () => {
      formRef.value.validate().then(() => {
        state.isLoading = true
        post(URLS.resetPassword, {
          token: state.form.token,
          password: md5(state.form.new_password)
        })
          .then(() => {
            Cookie.remove('isLogin')
            message.success(t('76173') as string)
            router.push({ name: 'login' })
          })
          .finally(() => {
            state.isLoading = false
          })
      })
    }
    const getFormTitle = () => {
      return state.form.token_type === 'active' ? t('75629') : t('75630')
    }

    return {
      ...toRefs(state),
      formTitle: computed(() => getFormTitle()),
      submit,
      formRef
    }
  }
})
