import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "login-module-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mail_outlined = _resolveComponent("mail-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_klook_phone_input = _resolveComponent("klook-phone-input")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.form,
    rules: _ctx.formRules
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.formTitle), 1),
      (_ctx.form.login_type === 'email')
        ? (_openBlock(), _createBlock(_component_a_form_item, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.form.username,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                size: "large",
                disabled: ""
              }, {
                suffix: _withCtx(() => [
                  _createVNode(_component_mail_outlined)
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.form.login_type === 'phone')
        ? (_openBlock(), _createBlock(_component_a_form_item, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_klook_phone_input, {
                value: _ctx.form.username,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.username) = $event)),
                size: "large",
                disabled: "",
                set: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, { name: "new_password" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_password, {
            value: _ctx.form.new_password,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.new_password) = $event)),
            size: "large",
            placeholder: _ctx.$t('75606'),
            autocomplete: ""
          }, null, 8, ["value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        class: "input-last",
        name: "confirm_new_password"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_password, {
            value: _ctx.form.confirm_new_password,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.confirm_new_password) = $event)),
            size: "large",
            placeholder: _ctx.$t('75607'),
            autocomplete: ""
          }, null, 8, ["value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            class: "operate-btn",
            size: "large",
            type: "primary",
            loading: _ctx.isLoading,
            block: "",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('75592')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}